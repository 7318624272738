<template>
  <div class="font-inter w-full">
    <div
      class="j-calendar-control flex items-center justify-between mb-3 h-9 font-inter rtl:flex-row-reverse"
    >
      <button
        class="flex items-center justify-center w-5 h-5 font-roboto cursor-pointer"
        @click.stop="changeByFilter(-1)"
      >
        <Icon icon="chevronLeft" height="20" width="20" color="primary-gray-700" />
      </button>
      <div
        class="flex items-center justify-center control-main font-semibold text-base tracking-normal font-inter text-primary-gray-700 cursor-pointer"
      >
        <div v-if="filterSelected === 0" @click.stop="$emit('filterChanged', 1)">
          {{ $t(`dateTime.${month}`) }} {{ translateNumberDate(calendarStats.year) }}
        </div>
        <div v-if="filterSelected === 1" @click.stop="$emit('filterChanged', 2)">
          {{ translateNumberDate(calendarStats.year) }}
        </div>
        <div v-if="filterSelected === 2">
          {{ translateNumberDate(calendarStats.year) }} -
          {{ translateNumberDate(calendarStats.year + 11) }}
        </div>
      </div>
      <button
        :disabled="isFutureDateDisabled && isFutureYear(calendarStats.startingDay)"
        class="flex items-center justify-center w-5 h-5 font-roboto cursor-pointer"
        @click.stop="changeByFilter(+1)"
      >
        <Icon icon="chevronRight" height="20" width="20" color="primary-gray-700" />
      </button>
    </div>
    <div v-if="filterSelected === 0" class="flex h-10 gap-3 justify-between mb-3">
      <div
        class="w-[215px] border border-primary-gray-300 px-3 py-2 rounded-lg flex gap-3 font-inter text-sm"
      >
        <div class="w-3/5 flex justify-center items-center text-primary-gray-900">
          {{ formatDateFromLocalToUTC(requiredDate, 'MMM D, YYYY') }}
        </div>
        <div class="w-2/5 flex justify-center items-center text-primary-gray-500">
          GMT{{ campusTimeZone }}
        </div>
      </div>
      <div
        class="w-[64px] border border-primary-gray-300 px-3 py-2 rounded-lg flex justify-center cursor-pointer items-center font-inter text-sm text-primary-gray-700 font-semibold"
        @click="resetCurrentDate()"
      >
        Today
      </div>
    </div>
    <div v-else-if="filterSelected === 1" class="flex h-10 gap-3 justify-between mb-3">
      <div
        class="w-full border border-primary-gray-300 px-3 py-2 rounded-lg flex justify-center items-center gap-3 font-inter text-base"
      >
        {{ formatDateFromLocalToUTC(requiredDate, dateTimeFormat.MONTH) }}
      </div>
    </div>
    <div v-else-if="filterSelected === 2" class="flex h-10 gap-3 justify-between mb-3">
      <div
        class="w-full border border-primary-gray-300 px-3 py-2 rounded-lg flex justify-center items-center gap-3 font-inter text-base"
      >
        {{ formatDateFromLocalToUTC(requiredDate, dateTimeFormat.YEAR) }}
      </div>
    </div>
    <div
      v-show="filterSelected === 0"
      class="j-calendar text-sm font-medium tracking-normal cursor-pointer text-primary-purple-700 font-inter"
    >
      <div v-for="(day, idx) in weekDays" :key="day + idx" class="font-medium">
        <div>{{ day }}</div>
      </div>

      <!-- Days in previous month -->
      <div
        v-for="(day, idx) in calendarStats.daysPaddedBefore"
        :key="'p' + day + idx + forceRender"
        class="text-light"
        @click.stop="
          /* eslint-disable vue/comma-dangle */
          clickDate('p', calendarStats.daysInPrevMonth - (calendarStats.daysPaddedBefore - day))
        "
      >
        <div class="day">
          {{
            translateNumberDate(
              calendarStats.daysInPrevMonth - (calendarStats.daysPaddedBefore - day),
            )
          }}
        </div>
      </div>

      <!-- Days in current month -->
      <div
        v-for="(day, idx) in calendarStats.daysInMonth"
        :key="'c' + day + idx + forceRender"
        :class="{ active: isDateSelected(day) }"
        @click.stop="clickDate('c', day)"
      >
        <div :class="{ currentDate: isCurrentSelected(day) }" class="day">
          {{ translateNumberDate(day) }}
        </div>
      </div>

      <!-- Days in next month -->
      <div
        v-for="(day, idx) in calendarStats.daysPaddedAfter"
        :key="'a' + day + idx + forceRender"
        class="text-light"
        @click.stop="clickDate('a', day)"
      >
        <div class="day">{{ translateNumberDate(day) }}</div>
      </div>
    </div>
    <div v-show="filterSelected === 1" class="j-months text-sm text-primary-gray-700 font-inter">
      <button
        v-for="(monthName, idx) in monthNames"
        :key="monthName"
        :class="{
          active:
            calendarStats.month === idx && //if on click current month is quals to the current index
            ((Object.prototype.toString.call(requiredDate) === '[object Date]' && //matching the data type of the selectDate with Date Object
              calendarYearSelected === requiredDate.getFullYear()) || //On changing the year in calendar and matching it with current selected Date
              getPossibleDate(requiredDate).getFullYear() === calendarStats.year), //checking if passed value is equal to the
        }"
        class="cursor-pointer disabled:text-primary-gray-400"
        :disabled="isFutureDateDisabled && isFutureMonthDisabled(idx - calendarStats.month)"
        @click="changeMonth(idx - calendarStats.month)"
      >
        <div>
          {{ $t(`dateTime.${monthName.substring(0, 3)}`) }}
        </div>
      </button>
    </div>
    <div v-show="filterSelected === 2" class="j-years text-sm text-primary-gray-700 font-inter">
      <button
        v-for="(year, idx) in 12"
        :key="'y' + idx"
        :class="{
          active: calendarYearSelected
            ? calendarYearSelected === calendarStats.year + idx
            : idx === 0,
        }"
        class="cursor-pointer disabled:text-primary-gray-400"
        :disabled="futureYear(calendarStats.year + idx - calendarStats.year)"
        @click="changeYear(calendarStats.year + idx - calendarStats.year)"
      >
        <div>{{ translateNumberDate(calendarStats.year + idx) }}</div>
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '@components/icons/icon.vue'
import { daysInMonth, firstDayOfMonth, isSameDate } from '../utils/utils'
import { formatDateFromLocalToUTC, isDateInFuture, isFutureYear } from '@src/utils/moment.util.js'
import { mapGetters } from 'vuex'
import { WEEK_DAYS, MONTH_NAMES } from '@src/constants/date-time-constants.js'
import dateTimeFormat from '@src/constants/date-time-constants.js'

export default {
  name: 'DatePicker',
  components: {
    Icon,
  },
  props: {
    selectedDate: {
      type: [Date, Object, String],
      default: null,
    },
    isFutureDateDisabled: {
      type: Boolean,
      default: false,
    },
    selector: {
      type: Number,
      default: 0,
    },
    filterSelected: {
      type: Number,
      default: 0,
    },
  },

  emits: ['filterChanged', 'click:date', 'click:changeMonth', 'click:changeMonth'],
  data() {
    return {
      dateTimeFormat,
      forceRender: 0,
      weekDays: WEEK_DAYS,
      monthNames: MONTH_NAMES,
      currentDate: new Date(),
      calendarYearSelected: null,
      calendarStats: {
        month: null,
        year: null,
        requiredDate: '',
        daysInMonth: 0,
        daysInPrevMonth: 0,
        startingDay: null,
        daysPaddedBefore: 0,
        daysPaddedAfter: 0,
      },
    }
  },

  computed: {
    month() {
      return this.monthNames[this.calendarStats.month]
    },
    ...mapGetters('layout', ['campusTimeZone']),
  },
  watch: {
    selectedDate: {
      immediate: true,
      handler(selectDate) {
        const date = selectDate instanceof Date ? selectDate : selectDate.start
        this.populateCalendarStats(date || this.currentDate)
        this.requiredDate = selectDate
        this.forceRender++
      },
    },
  },

  methods: {
    isFutureYear,
    formatDateFromLocalToUTC,
    /* checking if the passed date from the wrapper has the key "Start" and returning this key otherwise returning the full passed date */
    getPossibleDate(possibleDate) {
      // eslint-disable-next-line no-prototype-builtins
      if (possibleDate.hasOwnProperty('start')) {
        var date = new Date(possibleDate.start)
        return date
      } else {
        return possibleDate
      }
    },
    resetCurrentDate() {
      this.requiredDate = this.currentDate
      this.populateCalendarStats(this.currentDate)
      this.forceRender++
      this.$emit('click:date', this.currentDate)
    },
    populateCalendarStats(initDate) {
      const month = (this.calendarStats.month = initDate.getMonth())
      const year = (this.calendarStats.year = initDate.getFullYear())

      const noDaysInMonth = (this.calendarStats.daysInMonth = daysInMonth(year, month + 1))
      this.calendarStats.daysInPrevMonth = daysInMonth(year, month)

      const startingDay = (this.calendarStats.startingDay = firstDayOfMonth(year, month))

      const daysPaddedBefore = (this.calendarStats.daysPaddedBefore =
        startingDay.getDay() === 0 ? 6 : startingDay.getDay() - 1)

      const daysPaddedAfter = (this.calendarStats.daysPaddedAfter =
        7 - ((noDaysInMonth + daysPaddedBefore) % 7))

      if (daysPaddedBefore + daysPaddedAfter <= 7) {
        this.calendarStats.daysPaddedAfter = daysPaddedAfter + 7
      }
    },

    changeByFilter(status) {
      if (this.filterSelected === 0) {
        this.changeMonth(status)
      } else if (this.filterSelected === 1) {
        this.changeYear(status)
        futureYear(status)
      } else if (this.filterSelected === 2) {
        this.changeMutipleYears(status)
      }
    },

    changeMonth(status) {
      const newCalendarDate = new Date(
        this.calendarStats.year,
        this.calendarStats.month + status,
        1,
      )

      if (this.selector === 0) {
        this.populateCalendarStats(newCalendarDate)
        this.$emit('filterChanged', 0)
        this.forceRender++
      } else {
        this.$emit('click:date', newCalendarDate)
      }
      this.$emit('click:changeMonth', newCalendarDate)
    },
    isFutureMonthDisabled(status) {
      if (this.isFutureDateDisabled) {
        const newCalendarDate = new Date(
          this.calendarStats.year,
          this.calendarStats.month + status,
          1,
        )
        return isDateInFuture(newCalendarDate)
      }
    },

    futureYear(status) {
      if (this.isFutureDateDisabled) {
        const newCalendarDate = new Date(
          this.calendarStats.year + status,
          this.calendarStats.month,
          1,
        )
        return isDateInFuture(newCalendarDate)
      }
    },
    changeYear(status) {
      const newCalendarDate = new Date(
        this.calendarStats.year + status,
        this.calendarStats.month,
        1,
      )
      if (this.selector === 2) {
        this.$emit('click:changeMonth', newCalendarDate)
      } else {
        this.populateCalendarStats(newCalendarDate)
        this.calendarYearSelected = newCalendarDate.getFullYear()
        this.$emit('filterChanged', 1)
        this.forceRender++
      }
    },

    changeMutipleYears(status) {
      if (!this.calendarYearSelected) {
        this.calendarYearSelected = this.calendarStats.year
      }

      const newCalendarDate = new Date(
        this.calendarStats.year + status * 12,
        this.calendarStats.month,
        1,
      )
      this.populateCalendarStats(newCalendarDate)
    },

    clickDate(dateInMonth, date) {
      let month = this.calendarStats.month

      switch (dateInMonth) {
        case 'p':
          month = month - 1
          break
        case 'a':
          month = month + 1
          break
      }
      this.$emit('click:date', new Date(this.calendarStats.year, month, date))
    },

    isDateSelected(day) {
      const dateTemp = new Date(this.calendarStats.year, this.calendarStats.month, day)
      const date = this.requiredDate instanceof Date ? this.requiredDate : this.requiredDate.start
      return isSameDate(date, dateTemp)
    },
    isCurrentSelected(day) {
      const dateTemp = new Date(this.calendarStats.year, this.calendarStats.month, day)
      return (
        isSameDate(this.currentDate, dateTemp) && !isSameDate(this.currentDate, this.requiredDate)
      )
    },
    isYearSelected(year) {
      return false
    },
    translateNumberDate(inputCalendarStats) {
      inputCalendarStats = inputCalendarStats
        .toString()
        .split('')
        .map((arabNum) => this.$t(`rightBar.${arabNum}`))
        .join('')
      return inputCalendarStats
    },
  },
}
</script>

<style lang="scss" scoped>
.j-calendar {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-row-gap: 4px;
  width: 100%;
  text-align: center;
}

.j-months,
.j-years {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 4px;
  width: 100%;
  text-align: center;
}

.j-calendar > *,
.j-months > *,
.j-years > * {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.j-months > *,
.j-years > * {
  width: 100%;
  height: 65px;
}

.j-calendar .day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--primary-gray-700);
  letter-spacing: 0;
  cursor: pointer;
  border-radius: 100px;
}

.text-light .day {
  color: var(--primary-gray-400);
}

.j-calendar .active .day {
  color: var(--white);
  background-color: var(--primary-purple-600);
  border-radius: 100px;
}

.j-calendar .text-light {
  color: var(--primary-gray-400);
}

.j-months .active,
.j-years .active {
  color: var(--white);
  background-color: var(--primary-purple-600);
  border-radius: 8px;
}

.currentDate {
  background-color: var(--primary-gray-100);
}
</style>
