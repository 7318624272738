<template>
  <div class="w-full">
    <div class="flex items-center justify-between mb-3 h-9 font-inter rtl:flex-row-reverse">
      <div class="flex items-center justify-center">
        <img
          :src="require('../assets/images/chevron-left.svg')"
          alt="chevron left"
          class="cursor-pointer"
          @click.stop="changeByFilter(-1)"
        />
      </div>
      <div
        class="flex items-center justify-center control-main font-semibold text-base tracking-normal font-inter text-primary-gray-700 cursor-pointer"
      >
        <div v-if="filterSelected === 0" @click="filterSelected = 1">
          {{ $t(`dateTime.${month}`) }} {{ translateNumberDate(calendarStats.year) }}
        </div>
        <div v-if="filterSelected === 1" @click="filterSelected = 0">
          {{ translateNumberDate(calendarStats.year) }}
        </div>
      </div>
      <div class="flex items-center justify-center">
        <img
          :src="require('../assets/images/chevron-right.svg')"
          alt="chevron right"
          class="border-color-grey-strong cursor-pointer"
          @click.stop="changeByFilter(+1)"
        />
      </div>
    </div>
    <div
      class="w-full h-10 border border-primary-gray-300 px-3 py-2 rounded-lg flex font-inter text-xs mb-3"
    >
      <div class="w-3/4 flex justify-start items-center text-primary-gray-900">
        {{ formatDateFromLocalToUTC(selectDateRange[0].date, 'MMM D, YYYY') }}-
        {{ formatDateFromLocalToUTC(selectDateRange[1].date, 'MMM D, YYYY') }}
      </div>
      <div class="w-1/4 flex justify-center items-center text-primary-gray-500">
        GMT{{ campusTimeZone }}
      </div>
    </div>
    <div v-show="filterSelected === 0" class="j-calendar grid w-full text-center">
      <div
        v-for="(day, idx) in weekDays"
        :key="day + idx"
        class="flex items-center justify-center text-light h-10 w-10 text-sm font-inter text-primary-gray-700"
      >
        <div>{{ day }}</div>
      </div>

      <!-- Days in previous month -->
      <div
        v-for="(day, idx) in calendarStats.daysPaddedBefore"
        :key="'p' + day + idx + forceRender"
        class="text-primary-gray-400 text-sm font-inter"
        :class="{
          /* eslint-disable vue/comma-dangle */
          highlight: isDateInBetweenRange(
            calendarStats.daysInPrevMonth - (calendarStats.daysPaddedBefore - day),
            -1,
          ),
        }"
        @click.stop="
          clickDate('p', calendarStats.daysInPrevMonth - (calendarStats.daysPaddedBefore - day))
        "
      >
        <div class="day w-10 h-10">
          {{
            translateNumberDate(
              calendarStats.daysInPrevMonth - (calendarStats.daysPaddedBefore - day),
            )
          }}
        </div>
      </div>

      <!-- Days in current month -->
      <div
        v-for="(day, idx) in calendarStats.daysInMonth"
        :key="'c' + day + idx + forceRender"
        class="h-10 w-10 text-sm font-inter text-primary-gray-700"
        :class="{ active: isDateSelected(day), highlight: isDateInBetweenRange(day) }"
        @click.stop="clickDate('c', day)"
      >
        <div class="day">{{ translateNumberDate(day) }}</div>
      </div>

      <!-- Days in next month -->
      <div
        v-for="(day, idx) in calendarStats.daysPaddedAfter"
        :key="'a' + day + idx + forceRender"
        class="text-primary-gray-400 text-sm font-inter"
        :class="{ highlight: isDateInBetweenRange(day, +1) }"
        @click.stop="clickDate('a', day)"
      >
        <div class="day">{{ translateNumberDate(day) }}</div>
      </div>
    </div>

    <div
      v-show="filterSelected === 1"
      class="j-months grid grid-cols-3 w-full text-center cursor-pointer"
    >
      <div
        v-for="(monthName, idx) in monthNames"
        :key="monthName"
        :class="{ active: calendarStats.month === idx }"
      >
        <div class="font-medium" @click="changeMonth(idx - calendarStats.month)">
          {{ $t(`dateTime.${monthName.substring(0, 3)}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { daysInMonth, firstDayOfMonth, isSameDate } from '../utils/utils'
import { formatDateFromLocalToUTC } from '@src/utils/moment.util.js'
import { mapGetters } from 'vuex'
import { WEEK_DAYS, MONTH_NAMES } from '@src/constants/date-time-constants.js'

export default {
  name: 'DatePicker',
  props: {
    selectedDate: {
      type: Date,
      default: null,
    },
    selectDateRange: {
      type: Array,
      default: () => [],
    },
    isStart: {
      type: Boolean,
      default: false,
    },
    rangeIsSameMonth: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
  },
  emits: ['click:changeMonth', 'click:date'],
  data() {
    return {
      forceRender: 0,
      filterSelected: 0,
      weekDays: WEEK_DAYS,
      monthNames: MONTH_NAMES,
      currentDate: new Date(),
      calendarStats: {
        month: null,
        year: null,
        daysInMonth: 0,
        daysInPrevMonth: 0,
        startingDay: null,
        daysPaddedBefore: 0,
        daysPaddedAfter: 0,
      },
    }
  },

  computed: {
    month() {
      return this.monthNames[this.calendarStats.month]
    },
    ...mapGetters('layout', ['campusTimeZone']),
  },

  watch: {
    selectedDate: {
      immediate: true,
      handler(selectDate) {
        this.populateCalendarStats(selectDate || this.currentDate)
        this.forceRender++
      },
    },
    selectDateRange: {
      deep: true,
      immediate: true,
      handler(selectDateRange) {
        if (selectDateRange?.length === 2) {
          if (selectDateRange[0].stateOfCalendar) {
            if (this.isStart) {
              this.populateCalendarStats(selectDateRange[0].stateOfCalendar)
            } else {
              this.populateCalendarStats(
                new Date(
                  selectDateRange[0].stateOfCalendar.getFullYear(),
                  selectDateRange[0].stateOfCalendar.getMonth() + 1,
                  1,
                ),
              )
            }
          } else {
            const startRange = selectDateRange.find((range) => range.start === true)?.date
            const endRange = selectDateRange.find((range) => range.start === false)?.date

            if (this.isStart) {
              this.populateCalendarStats(
                startRange || new Date(endRange.getFullYear(), endRange.getMonth() - 1, 1),
              )
            } else {
              this.populateCalendarStats(
                endRange || new Date(startRange.getFullYear(), startRange.getMonth() + 1, 1),
              )
            }
          }
          this.forceRender++
        }
      },
    },
  },

  methods: {
    formatDateFromLocalToUTC,
    populateCalendarStats(initDate) {
      const month = (this.calendarStats.month = initDate.getMonth())
      const year = (this.calendarStats.year = initDate.getFullYear())

      const noDaysInMonth = (this.calendarStats.daysInMonth = daysInMonth(year, month + 1))
      this.calendarStats.daysInPrevMonth = daysInMonth(year, month)

      const startingDay = (this.calendarStats.startingDay = firstDayOfMonth(year, month))

      const daysPaddedBefore = (this.calendarStats.daysPaddedBefore =
        startingDay.getDay() === 0 ? 6 : startingDay.getDay() - 1)
      const daysPaddedAfter = (this.calendarStats.daysPaddedAfter =
        7 - ((noDaysInMonth + daysPaddedBefore) % 7))

      if (daysPaddedBefore + daysPaddedAfter <= 7) {
        this.calendarStats.daysPaddedAfter = daysPaddedAfter + 7
      }
    },

    changeByFilter(status) {
      if (this.filterSelected === 0) {
        this.changeMonth(status)
      } else if (this.filterSelected === 1) {
        this.changeYear(status)
      }
    },

    changeMonth(status) {
      const newCalendarDate = new Date(
        this.calendarStats.year,
        this.calendarStats.month + status,
        1,
      )
      this.populateCalendarStats(newCalendarDate)

      this.filterSelected = 0
      this.forceRender++
      this.$emit('click:changeMonth', newCalendarDate)
    },

    changeYear(status) {
      const newCalendarDate = new Date(
        this.calendarStats.year + status,
        this.calendarStats.month,
        1,
      )
      this.populateCalendarStats(newCalendarDate)
    },

    clickDate(dateInMonth, date) {
      let month = this.calendarStats.month
      switch (dateInMonth) {
        case 'p':
          month = month - 1
          break
        case 'a':
          month = month + 1
          break
      }
      this.$emit('click:date', {
        date: new Date(this.calendarStats.year, month, date),
        stateOfCalendar: new Date(this.calendarStats.year, this.calendarStats.month, date),
        start: this.isStart,
        nextDate: new Date(this.calendarStats.year, month, date + 6),
        nextStateOfCalendar: new Date(this.calendarStats.year, this.calendarStats.month, date + 6),
      })
    },

    isDateSelected(day, dayInMonth = 0) {
      const dateTemp = new Date(this.calendarStats.year, this.calendarStats.month + dayInMonth, day)

      const sameDay = this.selectDateRange.find((dateObj) => {
        if (isSameDate(dateObj.date, dateTemp)) {
          return true
        }

        return false
      })

      return !!sameDay
    },

    isDateInBetweenRange(day, changeMonth = 0) {
      if (this.selectDateRange.length === 2) {
        const current = new Date(
          this.calendarStats.year,
          this.calendarStats.month + changeMonth,
          day,
        ).getTime()
        const date1 = this.selectDateRange[0].date.getTime()
        const date2 = this.selectDateRange[1].date.getTime()

        if (date1 < date2) {
          if (date1 < current && current < date2) {
            return true
          }
        } else {
          if (date2 < current && current < date1) return true
        }

        return false
      }

      return false
    },

    translateNumberDate(inputCalendarStats) {
      inputCalendarStats = inputCalendarStats
        .toString()
        .split('')
        .map((arabNum) => this.$t(`rightBar.${arabNum}`))
        .join('')
      return inputCalendarStats
    },
  },
}
</script>

<style lang="scss" scoped>
.j-calendar {
  grid-template-columns: repeat(7, auto);
}

.j-calendar .day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 50%;
}

.j-calendar .active::after {
  background: #e9f2e9;
  border-radius: 50% 0% 0% 50%;
}
.j-calendar .active::before {
  background: #e9f2e9;
  border-radius: 50% 0% 0% 50%;
}
.j-calendar .active .day {
  color: var(--white);
  background-color: var(--primary-purple-600);
}

.j-calendar .active.highlight {
  background: var(--bg-color-lighter);
}

.j-calendar .highlight {
  background: var(--primary-gray-100);
}

.j-months .active,
.j-years .active {
  color: var(--white);
  background-color: var(--primary-green);
  border-radius: 7px;
}
</style>
