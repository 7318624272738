<template>
  <div ref="JCalendarContainer">
    <div ref="clickContainer" @click.prevent="togglePicker">
      <slot />
    </div>
    <div
      v-show="show && !disabled"
      ref="dropdownContainer"
      class="absolute px-2 sm:px-6 pt-5 pb-4 rounded-xl shadow-lg z-20 border bg-white date-picker-shadow border-primary-gray-200 mt-2 before:absolute before:-top-[7px] before:w-3.5 before:h-3.5 before:content-[''] before:bg-white before:border-t before:border-l before:rotate-45 before:rounded-tl"
      :class="[dropdownPosition, isDatePicker && ' w-[304px] sm:w-82', customClass]"
    >
      <DatePicker
        v-if="['date', 'month', 'year'].includes(picker)"
        ref="date-picker"
        :selector="selector"
        :is-future-date-disabled="isFutureDateDisabled"
        :filter-selected="filterSelected"
        :selected-date="clickedDate || modelValue"
        @click:date="selectDate"
        @filterChanged="filterAppliedDatePicker"
        @click:changeMonth="selectDate"
      />

      <DatePickerRange
        v-if="picker === 'date-range'"
        :key="show"
        ref="date-range-picker"
        :selected-range="clickedRange.length ? clickedRange : selectedRange"
        @range:selected="selectRange"
      />

      <div v-if="actions && actions.length > 0 && filterSelected === selector" v-bind="actionsAttr">
        <button
          v-for="(action, idx) in actions"
          :key="action.text + idx"
          v-bind="action.attr"
          @click.prevent="handleAction(action.handler)"
        >
          {{ action.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { isSameMonth, lastDayOfMonth, dateDiffInDays } from './utils/utils'
import { formatDate } from '@utils/moment.util'
import DATETIME_CONSTANT from '@src/constants/date-time-constants.js'
import DatePicker from './date-picker/DatePicker.vue'
import DatePickerRange from './date-range-picker/DateRangePicker.vue'
import { DATE_UNITS } from '@src/constants/date-time-constants.js'

export default {
  name: 'QDatePicker',
  components: {
    DatePicker,
    DatePickerRange,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    customClass: {
      type: String,
      default: 'left-0 before:left-12',
    },
    isFutureDateDisabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Date, Object, String],
      default: () => ({
        start: new Date(),
        end: new Date(new Date().getDate() + 7),
      }),
    },
    picker: {
      type: String,
      default: 'date',
      validator(value) {
        // The value must match one of these strings
        return ['date', 'month', 'date-range', 'year'].includes(value)
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        return {
          saveLastState: false,
        }
      },
    },
    actionsAttr: {
      type: Object,
      default: null,
    },

    mainContainerAttributes: {
      type: Object,
      default: null,
    },
    actions: {
      type: Array,
      default: null,
      validator(value) {
        const invalidItem = value.findIndex((act) => {
          if (
            typeof act.text === 'string' &&
            typeof act.handler === 'string' &&
            act.text.length > 0 &&
            act.handler.length > 0
          ) {
            return false
          } else {
            return true
          }
        })

        return invalidItem === -1
      },
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      DATE_UNITS,
      DATETIME_CONSTANT,
      show: false,
      selectedRange: [],
      clickedDate: null,
      clickedRange: [],
      selector: 0,
      filterSelected: 0,
      handlers: {
        apply: 'applySelectedDate',
        cancel: 'cancelSelectedDate',
      },
    }
  },
  computed: {
    dropdownPosition() {
      if (this.picker === 'date') return 'daily-view-position'
      else if (this.picker === 'date-range') return 'weeks-view-position'
      else return ''
    },
    isDatePicker() {
      return DATE_UNITS.includes(this.picker)
    },
  },

  watch: {
    picker: {
      immediate: true,
      handler(value) {
        if (value === 'date') {
          this.selector = 0
        } else if (value === 'month') {
          this.selector = 1
        } else if (value === 'year') {
          this.selector = 2
        } else {
          this.selector = -1
        }
      },
    },

    selector: {
      immediate: true,
      handler(value) {
        this.filterSelected = value
      },
    },
    emits: ['update:modelValue'],

    modelValue: {
      immediate: true,
      deep: true,
      handler(value) {
        if (this.picker === 'date-range') {
          if (value?.start && value?.end) {
            let stateOfCalendar = null
            const isSame = isSameMonth(value.start, value.end)

            if (!isSame) {
              const endDateState = new Date(
                value.end.getFullYear(),
                value.end.getMonth(),
                1,
              ).getDay()

              if (endDateState > 2 || endDateState === 0) {
                const lastDateOfStartingMonth = lastDayOfMonth(
                  value?.start.getFullYear(),
                  value?.start.getMonth() + 1,
                )
                const dateDifference = dateDiffInDays(value?.start, lastDateOfStartingMonth)

                const daysBefore = endDateState === 0 ? 5 : endDateState

                if (daysBefore > dateDifference) {
                  stateOfCalendar = new Date(value?.end.getFullYear(), value?.end.getMonth(), 1)
                }
              }
            }

            this.selectedRange = [
              { date: value.start, start: true, stateOfCalendar: stateOfCalendar },
              { date: value.end, start: isSame, stateOfCalendar: stateOfCalendar },
            ]
          } else {
            const startDate = new Date()
            const endDate = new Date()
            endDate.setDate(endDate.getDate() + 6)

            const isSame = isSameMonth(startDate, endDate)
            this.selectedRange = this.clickedRange = [
              { date: new Date(), start: true },
              { date: endDate, start: isSame },
            ]

            // this.$emit('update:modelValue', { start: startDate, end: endDate })
          }
        }
      },
    },
  },
  mounted() {
    document.addEventListener('click', this.clickEventHandler.bind(this))
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickEventHandler.bind(this))
  },
  methods: {
    togglePicker() {
      this.show = !this.show
    },

    resetDateSelection() {
      if (!this.options.saveLastState) {
        this.clickedDate = null
        if (this.selector === -1) {
          this.clickedRange = []
        } else {
          this.$refs['date-picker'].populateCalendarStats(this.modelValue)
        }
      }
    },
    clickEventHandler(e) {
      if (this.show) {
        if (!this.$refs?.clickContainer?.contains(e.target)) {
          if (!this.$refs?.dropdownContainer?.contains(e.target)) {
            this.togglePicker()
            this.resetDateSelection()
          }
        }
      }
    },

    selectDate(clickedDate) {
      this.clickedDate = clickedDate
    },

    selectRange(clickedRange) {
      this.clickedRange = clickedRange
    },

    applySelectedDate() {
      this.togglePicker()
      if (this.selector === -1) {
        this.$emit(
          'update:modelValue',
          this.clickedRange.length === 2
            ? { start: this.clickedRange[0].date, end: this.clickedRange[1].date }
            : this.modelValue,
        )
      } else if (this.selector === 2) {
        let date = this.clickedDate || this.modelValue
        this.$emit('update:modelValue', formatDate(date, DATETIME_CONSTANT.YEAR))
      } else {
        this.$emit('update:modelValue', this.clickedDate || this.modelValue)

        if (!this.options.saveLastState) {
          this.$refs['date-picker'].populateCalendarStats(this.clickedDate || this.modelValue)
        }
      }

      this.clickedDate = null
      this.clickedRange = []
    },

    cancelSelectedDate() {
      this.togglePicker()
      this.resetDateSelection()
    },

    handleAction(handler) {
      if (this.handlers[handler]) {
        this[this.handlers[handler]]()
      } else {
        this.$emit(handler)
      }
    },

    filterAppliedDatePicker(filterApplied) {
      this.filterSelected = filterApplied
    },

    translateNumberDate(inputCalendarStats) {
      inputCalendarStats = inputCalendarStats
        .toString()
        .split('')
        .map((arabNum) => this.$t(`rightBar.${arabNum}`))
        .join('')
      return inputCalendarStats
    },
  },
}
</script>
