<template>
  <div>
    <div class="j-range-header"></div>
    <div class="j-range-calendar">
      <div>
        <DatePicker
          is-start
          :range-is-same-month="rangeIsSameMonth"
          :select-date-range="clickedDateRange.length ? clickedDateRange : selectedRange"
          @click:date="selectDate"
        />
      </div>
      <div class="hidden xl:block">
        <DatePicker
          :range-is-same-month="rangeIsSameMonth"
          :select-date-range="clickedDateRange.length ? clickedDateRange : selectedRange"
          @click:date="selectDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { isSameMonth } from '../utils/utils'
import DatePicker from './DateRangePickerCalender.vue'

export default {
  name: 'DateRangePicker',
  components: { DatePicker },
  props: {
    selectedRange: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['range:selected'],

  data() {
    return {
      clickedDateRange: [],
      rangeIsSameMonth: true,
    }
  },
  computed: {},

  watch: {
    selectedRange: {
      immediate: true,
      deep: true,
      handler(range) {
        if (range.length === 2) {
          if (isSameMonth(range[0].date, range[1].date)) {
            this.rangeIsSameMonth = true
          } else {
            this.rangeIsSameMonth = false
          }
        }
      },
    },
  },

  methods: {
    selectDate(clickedDate) {
      var currentClickedDate = {
        date: clickedDate.date,
        stateOfCalendar: clickedDate.stateOfCalendar,
        start: clickedDate.start,
      }

      var nextClickedDate = {
        date: clickedDate.nextDate,
        stateOfCalendar: clickedDate.nextStateOfCalendar,
        start: clickedDate.start,
      }

      this.clickedDateRange[0] = currentClickedDate
      this.clickedDateRange[1] = nextClickedDate

      this.$emit('range:selected', this.clickedDateRange)
      this.clickedDateRange = []
    },
  },
}
</script>

<style lang="scss" scoped>
.j-range-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 26px;
}

.j-range-calendar div div.day {
  width: 32px;
  height: 32px;
}
</style>
